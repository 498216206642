:root {
  --bjelin-green: #6b7744;
  --bjelin-grey: #959797;
  --bjelin-middlegrey: #757575;
  --bjelin-darkgrey: #3e3e3e;
}

html {
  background-color: #eee;
  height: -webkit-fill-available;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #eee;
  font-family: Helvetica, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.form-field {
  display: block;
  padding: 1em;
}

.form-field label {
  display: block;
}

.form-field input,
.form-field select {
  display: block;
  width: 100%;
  height: 2.4em;
  line-height: 2em;
}

.form-field input[type="submit"] {
  font-size: 1.4em;
  height: 3em;
  font-weight: bold;
}
