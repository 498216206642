.wrapper {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2vmax;
}
ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
li {
  height: 5em;
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
}
